<template>
  <layout-horizontal-settings>
    <router-view />
  </layout-horizontal-settings>
</template>

<script>
import LayoutHorizontalSettings from '@/layouts/horizontal/LayoutHorizontalSettings.vue'

export default {
  name: 'Settings',
  components: {
    LayoutHorizontalSettings,
  },
}
</script>

<style scoped>

</style>
